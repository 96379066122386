* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
 
}
* :not(div){
  font-family: 'Prompt', sans-serif !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 2rem;
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F59726;
}

.docking-panel .docking-panel-container-solid-color-b .explode-submenu {
  display: none !important;
}

.adsk-viewing-viewer.dark-theme .docking-panel-container-solid-color-b {
  background-color: rgba(34,34,34,.94) !important;
  display: none !important;
}

#demo-simple-select{
  font-family: 'Prompt', sans-serif !important;
}

/* * :not(#forgeViewer) {
  font-family: 'Kanit', sans-serif !important;
} */

body {
    /* font-family: "Prompt", "Poppins", sans-serif; */
    margin: 0;
    background: #e5e5e5;
  }
  /* .viewer{
    background-color: #f2f3f4;
    border-style: solid;
    border-width: 20px;
    border-radius: 30px;
    border-color: #e0e0e1;
    font-family: "Prompt", "Poppins", sans-serif;
  } */
  
  .footer-Text {
    padding-top: 10px;
  }
  
  .menubar {
    border-radius: 30px;
  }
  
  .menubar-logo-img {
    width: 250px;
    height: 60px;
    background-color: #cdcece;
    padding: 10px 30px 10px 30px;
    border-radius: 50px;
  }
  
  .menubar-selectModel {
    display: none;
  }
  
  .NavDropdownImg {
    padding-right: 0;
    /* display: none; */
    text-align: right;
    margin-right: 0;
  }
  
  /*  */
  .header-divider {
    background-color: #e0e0e1;
  }
  
  .drop-down-menu {
    /* background-color: red; */
    border-radius: 50px;
    box-shadow: "0px 8px 18px 0 rgba(0,0,0,0.14)";
  }
  
  .header-drop-down-name {
    font-size: 20px;
    /* font-family: Source Sans Pro; */
    color: #333333;
    font-weight: bold;
  }
  
  .nav-item {
    font-size: 20px;
    /* font-family: Source Sans Pro; */
    color: #616161;
    font-weight: normal;
  }
  
  .nav-item:hover {
    color: #ff7255;
  }
  .Clear {
    clear: both;
  }
  
  .Stage {
    margin: 15px;
    height: 100%;
    background-color: #ffffff;
    border-radius: 50px;
  }
  
  .Layer {
    padding-top: 30vh;
    width: 100%;
    height: 100%;
    background-color: rgba(229, 229, 229, 0.6);
    box-shadow: 30px 70px 120px (rgba(27, 49, 66, 0.13));
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    
  }
  
  .AboveLoginBox {
    margin-top: -140px;
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;
  }
  .AboveRegisterBox {
    margin: auto;
    margin-top: -140px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
  }
  
  .Icon-Logo-bim {
    /* margin-top: 30px; */
    margin-left: 30px;
  }
  
  .LoginBox {
    background-color: rgb(255, 255, 255);
    margin: auto;
    height: 307px;
    width: 100%;
    max-width: 1350px;
    border: 10px solid rgb(255, 255, 255);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    padding: 0 2rem;
    height: 100%;
    padding-bottom: 2rem;
    gap: 2rem;
  }



  .RegisterBox {
    /* background-color: rgb(255, 255, 255);
    margin: auto;
    height: 500px;
    width: 1294px;
    border: 10px solid rgb(255, 255, 255);
    border-radius: 50px;
    display: flex;
    justify-content: center; */
    background-color: rgb(255, 255, 255);
    margin: auto;
    height: 100%;
    /* width: 100%; */
    max-width: 850px;
    border: 10px solid rgb(255, 255, 255);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    padding: 0 2rem;
    padding-bottom: 2rem;
    align-items: center;
  }
  
  .Text-01 {
    margin-top: 0.9cm;
    font-size: 28px;
    font-weight: 700;
    color: #797b7e;
    /* text-indent: 2cm; */
    text-align: center;
    margin-bottom: 0;
  }
  
  .Text-02 {
    /* margin-bottom: 0.8cm; */
    font-size: 24px;
    font-weight: 500;
    color: #797b7e;
    /* text-indent: 2.5cm; */
    text-align: center;
    margin-bottom: 0;
  }

  
  
  .LoginBox-Left {
    width: 100%;
    /* float: left; */
  }
  
  .LoginBox-Right {
    width: 100%;
    /* float: left; */
  }
  
.RegisBox{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
}
  
  .TextFieldZone {
    margin-top: 16px;
    display: flex;
    width: 100%;
    float: left;
  }
  
  .Icon-Logo {
    /* margin-left: 2.5cm; */
    margin-top: 1rem;
  }
  
  .TextField {
    margin-top: 15px;
    margin-left: 1.25%;
    width: 100%;
    float: left;
    background: rgba(196, 196, 196, 0.3);
    border: 5px solid rgba(196, 196, 196, 0.01);
    border-radius: 60px;
    font-size: 20px;
    font-family: "Prompt", "Poppins", sans-serif;
    color: #7c7b7b;
    text-indent: 0.5cm;
    font-weight: 500;
    height: 100%;
  }
  
  .TextButton-01 {
    margin-top: 2vh;
    margin-right: 0.75cm;
    float: right;
    text-align: right;
    line-height: 0.141cm;
    border-style: none;
    background: rgba(0, 0, 0, 0);
    font-family: "Prompt", "Poppins", sans-serif;
    font-size: 18px;
    font-weight: bold;
  }
  
  .Text-03 {
    margin-top: 2vh;
    margin-right: 1cm;
    float: right;
    text-align: right;
    line-height: 10px;
  }
  .Text-03 p{
    margin-bottom: 0 !important;
  }
  .SocialLoginBox {
    margin: auto;
    margin-top: 0.5cm;
    background: #eeeeee;
    width: 100%;
    padding-bottom: 2rem;
    height: 100%;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .Text-04 {
    padding-top: 3vh;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    color: #797b7e;
  }
  
  .GoogleButton {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .GoogleButton-Button {
    display: flex;
    justify-content: center;
    align-items:  center;
    width: 100%;
    max-width: 257px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      30px 70px 120px rgba(27, 49, 66, 0.13);
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }

  .GoogleButton-Button:hover{
    max-width: 280px;

  }
  
  .GoogleButton-Icon {
    width: 35px;
  }
  
  .GoogleButton-Text {
    font-size: 24px;
    text-indent: 12px;
    font-family: "Propmt", "Poppins", sans-serif;
    color: red;
    font-weight: bold;
    margin: 0;
  }

  

  .GoogleButton-Text p {
    margin: 0;
    padding: 0.2rem;
  }
  
  .FacebookButton {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  
  .FacebookButton-Button {
   display: flex;
    justify-content: center;
    align-items:  center;
    width: 100%;
    max-width: 257px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      30px 70px 120px rgba(27, 49, 66, 0.13);
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }

  .FacebookButton-Button:hover {
    max-width: 280px;
   
   }
  
  .FacebookButton-Icon {
    width: 35px;
  }
  
  .FacebookButton-Text {
    float: left;
    font-size: 24px;
    text-indent: 12px;
    font-family: "Propmt", "Poppins", sans-serif;
    color: #1877f2;
    font-weight: bold;
    margin: 0;
  }

  .FacebookButton-Text p{
    margin: 0;
    padding: 0.2rem;
  }
  
  .UnderLoginBox {
    margin: auto;
    margin-top: 22px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2rem;

  }
  
  .LoginButton-Button {
    width: 100%;
    max-width: 182px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      30px 70px 120px rgba(27, 49, 66, 0.13);
    border-radius: 50px;
  }
  .LoginButton-Button:hover .LoginButton-Text{
    color: #FF8B00;
  }
  
  .LoginButton-Text {
    font-size: 20px;
    text-indent: 0px;
    font-family: "Prompt", "Poppins", sans-serif;
    color: #7c7b7b;
    font-weight: bold;
    margin: 0;
    padding: 0.2rem;
  }

  .LoginButton-Button:hover .LoginButton-Text{
    color: #FF8B00;
  }
  
  .RegisterButton-Button {

    width: 182px;
    max-width: 182px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      30px 70px 120px rgba(27, 49, 66, 0.13);
    border-radius: 50px;
  }
  .linkSign{
    width: 182px;
    max-width: 182px;
  }
  
  .RegisterButton-Text {
    font-size: 20px;
    text-indent: 0px;
    font-family: "Prompt", "Poppins", sans-serif;
    color: #7c7b7b;
    font-weight: bold;
    margin: 0;
    padding: 0.2rem;
  }

  .RegisterButton-Button:hover .RegisterButton-Text{
    color: #FF8B00;
  }
  
  
  .Credit {
    width: 100%;
    margin: auto;
    margin-top: 3rem;
  }
  
  .FootageLine {
    height: 3px;
    border-width: 0;
    color: #ffffff;
    background-color: #ffffff;
  }
  
  .FootageMessage {
    font-size: 20px;
    margin-top: 8px;
    text-align: center;
    font-family: "Prompt", "Poppins", sans-serif;
    color: #ffffff;
    font-weight: 500;
  }

  .swal2-container.swal2-center>.swal2-popup{
    background-color: #E5E5E5 !important;
    border-radius: 2rem;
  }
  
  /*****************************************************/
  /*****UserPermission.css*****/
  idcard {
    min-width: 25%;
    background: #ffffff;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1.5px solid rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    display: grid;
    margin: 10px;
    padding: 20px;
  }


  .forge-spinner img{
    content:url("/public/Icon\ TIS.png") !important;
    width: 1200px !important;
    animation: loading 1s normal forwards;
}

@keyframes loading {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

  .forge-spinner svg{
    opacity: 0 !important;
    display: none !important;
  }

  #navTools{
    background-color: white !important;
  }

  #modelTools{
    background-color: white !important;
  }

  #settingsTools{
    background-color: white !important;
  }
  #toolbar-sectionToolSubMenu{
    background-color: white !important;
  }

  #toolbar-orbitTools{
    display: none !important;
  }
  #toolbar-cameraSubmenuTool{
    display: none !important;
  }

  #toolbar-explodeTool{
    display: none !important;
  }

  /* .adsk-button-icon{
    color: #333333 !important;
  }

  .adsk-control .adsk-button .active{
    color: #FF8B00 !important;
  } */
  .adsk-viewing-viewer.dark-theme .adsk-control-group{
    color: #333333 !important;
  }
  .adsk-viewing-viewer.dark-theme .adsk-button.active, .adsk-viewing-viewer.dark-theme .adsk-button:focus{
    color: #FF8B00 !important;
  }
  .adsk-viewing-viewer.dark-theme .adsk-button.active, .adsk-viewing-viewer.dark-theme .adsk-button:focus{
    box-shadow: none !important;
  }
  .adsk-viewing-viewer.dark-theme .adsk-button:hover{
    color: #FF8B00 !important;
    border-color: #FF8B00 !important;
  }
  .cs-message-input__content-editor-wrapper{
    background-color: white !important;
    border: #E5E5E5 solid 2px;

  }
  .cs-message-input__content-editor{
    background-color: white !important;
  }
  .cs-button--attachment{
    color: #F7941D !important;
  }
  .cs-button--send{
    color: #F7941D !important;
  }

  .cs-conversation-header{
    background-color: #e5e5e5 !important;
  }
  .cs-chat-container .cs-message-input{
    background-color: #e5e5e5 !important;
  }
  .cs-conversation-header__content .cs-conversation-header__user-name{
    background-color: #e5e5e5 !important;
    color: rgb(100 100 100) !important;
  }
  .ps__thumb-y{
    background-color: #F7941D !important;
  }
  .cs-message-list{
    background-color: #fcd7aa !important;
  }
  .cs-message-separator{
    color: #909090 !important;
    background-color: #fcd7aa !important;
  }
  .cs-message-separator::before, .cs-message-separator::after{
    background-color: #F7941D !important;
  }

  .cs-message--incoming .cs-message__content{
    background-color: #e5e5e5 !important;
  }
  .cs-message--outgoing .cs-message__content{
    background-color: #F7941D !important;
  }
  .ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking{
    background-color: #e5e5e5 !important;
  }


  /* @media screen and (max-width: 1416px) {
    .TextFieldZone img{
      width: 30px !important;
      height: 30px !important;
    }
    .AboveLoginBox{
      margin-bottom: 1.5rem;
    }
    .LoginBox {
     flex-direction: column;
     align-items: center;
     height: 100%;
    }
    .Text-02{
      font-size: 16px;
    }
   
   
  } */

  @media screen and (max-width: 1016px) {
    .TextFieldZone img{
      width: 30px !important;
      height: 30px !important;
    }
    .AboveLoginBox{
      margin-bottom: 1.5rem;
    }
    .LoginBox {
     flex-direction: column;
     align-items: center;
     height: 100%;
    }
    .Text-02{
      font-size: 16px;
    }
    .Icon-Logo-tis{
      width: 320px;
    }
    .Icon-Logo-bim{
      width: 320px;

    }

  }

  @media screen and (max-width: 805px) {
    .Icon-Logo-tis{
      width: 250px;
    }
    .Icon-Logo-bim{
      width: 250px;

    }
    .Text-01{
      font-size: 16px;
    }
    .FootageMessage{
      font-size: 16px;
    }

    .GoogleButton-Icon {
      width: 25px;
    }
    .GoogleButton-Text{
      font-size: 18px;
    }
    .FacebookButton-Icon {
      width: 25px;
    }
    .FacebookButton-Text{
      font-size: 18px;
    }
    .LoginButton-Text{
      font-size: 16px;
    }

    .RegisterButton-Text{
      font-size: 16px;
    }
    .TextField{
      font-size: 16px;
    }
    .Text-04{
      font-size: 16px;

    }

  }

  @media screen and (max-width: 655px) {


    .Layer {
      padding-top: 25vh;

      
    }

    .AboveLoginBox{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .Layer{
      padding-left: 0;
      padding-right: 0;
    }
    .Icon-Logo-tis{
      width: 180px;
    }
    .Icon-Logo-bim{
      width: 180px;

    }
    .Text-01{
      font-size: 14px;
    }
    .Text-02{
      font-size: 14px;
    }
    .LoginBox{
      width: 100%;
    }
    .FootageMessage{
      font-size: 12px;
    }
  }

  @media screen and (max-width: 516px) {
    .AboveLoginBox{
    display: flex;
    flex-direction: column;
    text-align: center;
    }
    .Icon-Logo-tis{
      width: 200px;
    }
    .Icon-Logo-bim{
      width: 150px;
      margin-left: 0;
      margin-top: 1rem;
    }

  }