.Navbar{
    background: #E5E5E5;
    width: 100%;
    height: 100%;
    position: -webkit-sticky;
    position: sticky ;
    top: 0;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 3rem;
    align-items: center;
}

.Navbar-items{
    display: flex;
    width: 400px;
    justify-content: space-between;
    align-items: center;
}

.Nav-list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Nav-search{
    display: flex;
    width: 400px;
    justify-content: space-between;
    align-items: center;
}

.list-menu{
    transition: all 0.3s ease-in-out;
}

.list-menu:hover{
    transform: scale(1.1);
    cursor: pointer;

}

.Chat-menu{
    background-color:#F7941D;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    transition: all 0.3s ease-in-out;
}

.Chat-menu:hover{
    transform: scale(1.1);
    transform: rotate(15deg);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;

}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-disabled{
    opacity: 1 !important;
}

/* .css-1480iag-MuiInputBase-root-MuiInput-root:after{
    border-bottom: 2px solid #FF8B00 !important;
}


.MuiInputLabel-root.Mui-focused{
    color: #FF8B00 !important;
} */


@media screen and (max-width: 1070px) {
    
    .Navbar{
        padding-top: 0.5rem;
        justify-content: space-between;
        overflow: hidden;
        /* flex-direction: column; */
        height: 140px;
        z-index: 9 !important;
    }
    .LogoBimMenu{
        display: flex;
        align-self: center;
        justify-self: center;
    }
    .css-574lq2-MuiFormControl-root{
        min-width: 0px !important;
    }
    .Nav-search{
        width: 400px;
        justify-content: flex-end;
    }
    .Chat-menu{
        width: 50px !important;
        height: 50px !important;
    }
    .Chat-menu img{
        width: 40px !important;
        height: 40px !important;
    }
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
        width: 250px;
        height: 45px;
    }
    .Box-App{
        padding: 0 !important;
    }
    /* .project-box{
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 3rem;
        flex-wrap: wrap;
    } */
    .LogoBimMenu{
        display: none !important;
    }
    .Navbar-items{
        width: 500px !important;
    }
    .list-menu{
        justify-content: space-evenly !important;
        gap: -3rem;
        width: 70px !important;
    }
    #root > div > div > div.Navbar > div.Nav-search > button{
        margin: 0 !important;
    }
    #root > div > div > div.Navbar > div.Nav-search > button > img{
        width: 45px !important;
        height: 45px !important;
    }
    #root > div > div > div.footer > h2{
        margin-top: 1.1rem !important;
    }
    .css-sghohy-MuiButtonBase-root-MuiButton-root{
        font-size: 16px !important;
    }
    .css-p51awj{
        max-width: none !important;
        width: 300px !important;
    }
    /* .projectInput{

    } */
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
        align-self: center !important;
        /* width: 180px !important; */
    }
  }

  @media screen and (max-width: 955px) {
    .Navbar{
        padding-top: 0.5rem;
        justify-content: flex-start;
        overflow: hidden;
        flex-direction: column;
        height: 140px;
        z-index: 9 !important;
    }
    .LogoBimMenu{
        display: flex;
        align-self: center;
        justify-self: center;
    }
    .css-574lq2-MuiFormControl-root{
        min-width: 0px !important;
    }
    .Nav-search{
        width: 400px;
        justify-content: center;
    }
    .Chat-menu{
        width: 40px !important;
        height: 40px !important;
    }
    .Chat-menu img{
        width: 30px !important;
        height: 30px !important;
    }
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
        width: 250px;
        height: 45px;
    }
    .Box-App{
        padding: 0 !important;
    }
    .project-box{
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 3rem;
        /* flex-wrap: wrap; */
    }
    .LogoBimMenu{
        display: none !important;
    }
    .Navbar-items{
        width: 480px !important;
    }
    .list-menu{
        justify-content: space-evenly !important;
        width: 60px !important;
    }
    #root > div > div > div.Navbar > div.Nav-search > button{
        margin: 0 !important;
    }
    #root > div > div > div.Navbar > div.Nav-search > button > img{
        width: 45px !important;
        height: 45px !important;
    }
    #root > div > div > div.footer > h2{
        margin-top: 1.1rem !important;
    }
    .css-sghohy-MuiButtonBase-root-MuiButton-root{
        font-size: 16px !important;
    }
    .css-p51awj{
        max-width: none !important;
        width: 300px !important;
    }
    /* .projectInput{

    } */
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
        align-self: center !important;
        /* width: 180px !important; */
    }
    .fc{
        font-size: 14px !important;
    }

  }

  @media screen and (max-width: 805px) {
    

  }

  @media screen and (max-width: 655px) {
    
  }

  @media screen and (max-width: 516px) {

    .Navbar{
        padding:0.5rem 0;
        justify-content: flex-start;
        overflow: hidden;
        flex-direction: column;
        height: 100%;
        z-index: 9 !important;
        /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;

    }
    .LogoBimMenu{
        display: flex;
        align-self: center;
        justify-self: center;
    }
    .css-574lq2-MuiFormControl-root{
        min-width: 0px !important;
    }
    .Nav-search{
        width: 400px;
        justify-content: center;
    }
    .Chat-menu{
        width: 40px !important;
        height: 40px !important;
    }
    .Chat-menu img{
        width: 30px !important;
        height: 30px !important;
    }
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
        width: 250px;
        height: 45px;
    }
    .Box-App{
        padding: 0 !important;
    }
    .project-box{
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
        gap: 3rem;
        /* flex-wrap: wrap; */
    }
    .LogoBimMenu{
        display: none !important;
    }
    .Navbar-items{
        width: 300px !important;
    }
    .list-menu{
        justify-content: space-evenly !important;
        width: 60px !important;
    }
    #root > div > div > div.Navbar > div.Nav-search > button{
        margin: 0 !important;
    }
    #root > div > div > div.Navbar > div.Nav-search > button > img{
        width: 45px !important;
        height: 45px !important;
    }
    #root > div > div > div.footer > h2{
        margin-top: 1.1rem !important;
    }
    .css-sghohy-MuiButtonBase-root-MuiButton-root{
        font-size: 16px !important;
    }
    .css-p51awj{
        max-width: none !important;
        width: 300px !important;
    }
    /* .projectInput{

    } */
    .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
        align-self: center !important;
        /* width: 180px !important; */
    }
    .fc{
        font-size: 10px !important;
    }
  }