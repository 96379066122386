
#nestedViewerExtensionButton {
    object-fit: contain;
    background-image: url('/public/Icon\ Blueprint.svg');
    /* background-size: 24px;
    background-repeat: no-repeat;
    background-position: center; */
    /* filter: grayscale(20%); */
}

#nestedViewerExtensionButton:hover {
    /* background-image: url('https://img.lovepik.com/free-png/20210926/lovepik-love-png-image_401496358_wh1200.png'); */

 /*  background-size: 24px;
  background-image: url('https://cdn-icons-png.flaticon.com/512/182/182654.png');

  background-repeat: no-repeat;
  background-position: center; */
  
  filter: hue-rotate(0deg) saturate(150%);
  /* filter: invert(59%) sepia(83%) saturate(1301%) hue-rotate(348deg) brightness(102%) contrast(94%); */
  background-image: url('/public/Icon\ Blueprint\ hover.svg');

}


#nestedViewerExtensionButton.active {
  background-image: url('/public/Icon\ Blueprint\ hover.svg');
}

#nestedViewerExtensionDropdown {
    background: #fff;
    color: rgb(0, 0, 0);
}
#nestedViewerExtensionToolbar{
  background-color: #fff;
}

#loader {
    position: absolute;
    left: 35%;
    top: 50%;
    z-index: 1;
    width: 450px;
    height: 120px;
    margin: -76px 0 0 -76px;
    /* border: 16px solid #f3f3f3; */
    /* border-radius: 50%; */
    /* border-top: 16px solid #3498db; */
    /* -webkit-animation: 2s linear infinite;
    animation: 2s linear infinite; */
    /* background-image: url('https://img.lovepik.com/free-png/20210926/lovepik-love-png-image_401496358_wh1200.png'); */
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Add animation to "page content" */
  .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
  }
  
  @-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 } 
    to { bottom:0px; opacity:1 }
  }
  
  @keyframes animatebottom { 
    from{ bottom:-100px; opacity:0 } 
    to{ bottom:0; opacity:1 }
  }
  
  #myDiv {
    display: none;
    text-align: center;
  }

  .docking-panel .docking-panel-container-solid-color-b .explode-submenu {
    display: none !important;
  }