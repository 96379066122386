/*BG Form Notification POPUP*/
.css-rnmm7m-MuiPaper-root-MuiDialog-paper{
    background-color: #E5E5E5 !important;
    border-radius: 1rem !important;
}
.css-1hju3x6-MuiPaper-root-MuiDialog-paper{
    background-color: #E5E5E5 !important;
    border-radius: 1rem !important;
}
.css-1chwz6k-MuiPaper-root-MuiDialog-paper{
    background-color: #E5E5E5 !important;
    border-radius: 1rem !important;
}
/*Upload BTN Form Notification POPUP*/
.css-1fu7jd5-MuiButtonBase-root-MuiButton-root{
    background-color: #FFFFFF !important;
    color: #7C7B7B!important;
    border-radius: 2rem !important;
    font-weight: bolder !important;
}
.css-1fu7jd5-MuiButtonBase-root-MuiButton-root:hover{
    color: #FF8B00 !important;

}
/* #deletePermis{
    padding: 0 !important;
    height: 35px !important;
    background-color: #E5E5E5 !important;
} */
#listItemBox{
    padding-left: 1rem !important;
}
/*Submit BTN Form Notification POPUP*/
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    background-color: #ffffff !important;
    color: #7C7B7B!important;
    border-radius: 2rem !important;
    font-weight: bold !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    min-width: 35px !important;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
    color: #FF8B00 !important;
    background-color: #ffffff !important;
}

/* .css-sghohy-MuiButtonBase-root-MuiButton-root{
    background-color: #FFFFFF !important;
    color: #7C7B7B!important;
    border-radius: 2rem !important;
    font-weight: bolder !important;
    font-size: 18px !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root:hover{
    color: #FF8B00 !important;
} */


.css-1fu2e3p-MuiPaper-root-MuiDialog-paper{
    background-color: #E5E5E5 !important;
    border-radius: 1rem !important;
}

.MuiDialog-paperFullWidth{
    background-color: #E5E5E5 !important;
    border-radius: 1rem !important;
}

/*Form Notify popup*/
/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    color: #FF8B00 !important;
}

.css-1aquho2-MuiTabs-indicator{
    background-color: #FF8B00 !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
    color: #FF8B00 !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color:#FF8B00 !important;
    border-width:2px;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color:#FF8B00 !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color:#FF8B00 !important;
}

.css-p51awj {
    margin-top: 0.5rem;
} */

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    border-radius: 1.5rem !important;
    margin-top: .2rem;
}


.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root{
    background-color: #ffffff !important;
    Border-radius: 2rem !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
    background-color: #ffffff !important;
    Border-radius: 2rem !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    background-color: #ffffff !important;
    Border-radius: 2rem !important;
} */

label {
    cursor: pointer;
    /* Style as you please, it will become the visible UI component. */
}
 
 #upload-model-label {
    /* background-color: #7c7b7b44;
    padding: 0.3rem;
    border-radius: 2rem; */
    padding: 0.3rem 1rem;
    background-color: #FFFFFF !important;
    color: #7C7B7B!important;
    border-radius: 2rem !important;
    font-weight: bolder !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
    font-size: 14px !important;
 }
 .swal2-container {
    z-index: 100000 !important;
  }
  .fc-h-event .fc-event-title-container{
    transition: all 0.2s ease-in-out;
 }
  .fc-h-event .fc-event-title-container:hover{
    cursor: pointer;
    transform: translateX(0.5rem);
  }

  .btn-app{
    background-color: #249712;
    border:0;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    color: white;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
  }
  .btn-app:hover{
    transform: rotate(15deg);
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  }

  .btn-rej{
    background-color: #FF0000;
    border:0;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    color: white;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
  }
  .btn-rej:hover{
    transform: rotate(15deg);
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  }


  .cardApp{
    /* width:'338px' , backgroundColor:'white' , borderRadius:'1rem' , padding:'1.5rem' , boxShadow:'inset 0px 4px 4px rgba(0, 0, 0, 0.25)' */
    width: 338px;
    background-color: #FFFFFF;
    border-radius: 1rem;
    padding: 1.5rem;
    transition: all 0.2s ease-in-out;
  }

  .cardApp:hover{
    background-color: #ffffff9f;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  }

  

