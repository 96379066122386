.logoLoading{
    width: 500px;
    /* animation: loading 1s normal forwards; */
}
.logoLoadingbim{
    margin-left: 3rem;
}

/* @keyframes loading {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
} */

@media screen and (max-width: 1070px) {

    .logoLoading{
        width: 400px;
    }
    .logoLoadingbim{
        width: 300px;
        margin-left: 3rem;
    }

  }


@media screen and (max-width: 955px) {

    .logoLoading{
        width: 380px;
    }
    .logoLoadingbim{
        width: 280px;
        margin-left: 3rem;
    }

  }

@media screen and (max-width: 516px) {

    .fetchData{
        flex-direction: column !important;
        gap: 2rem;

    }
    .logoLoading{
        width: 340px;
    }
    .logoLoadingbim{
        width: 250px;
        margin: 0;
    }

  }