.grid-container {
    display: grid;
    background-image: url("/src/assets/Img/BG.png");
    height: 100vh;
}
.form-login{
  margin-left: 10%;
  margin-top: 10%;
}
.login-form{
  margin-top: 0.5%;
  width: 25%;
}
.form-register{
  margin-left: 10%;
  margin-top: 1%;
}
.login-register{
  margin-top: 1%;
  width: 25%;
}